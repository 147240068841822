import { Injectable, OnDestroy } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";


@Injectable({
  providedIn: 'root',
})
export class UserOrgService implements OnDestroy {
  private storageKey = 'Sup_Org_#';
  private org$: Observable<number>;
  private orgSubject: BehaviorSubject<number>;

  constructor() {
    const encryptedOrgId = sessionStorage.getItem(this.storageKey) as any;
    let orgId;
    if (encryptedOrgId)
      orgId = parseInt(atob(encryptedOrgId));

    this.orgSubject = new BehaviorSubject<number>(orgId);
    this.org$ = this.orgSubject.asObservable();
  }

  ngOnDestroy(): void {
    throw new Error("Method not implemented.");
  }

  updateOrg(orgId: number) {
    sessionStorage.setItem(this.storageKey, btoa(orgId.toString()));
    this.orgSubject.next(orgId);
  }

  get orgChanges() {
    return this.org$;
  }

  selectedOrg: any;

}
